.glassmorphic {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  transition: background 0.3s ease-in-out;
}

.glassmorphic:hover {
  background: rgba(255, 255, 255, 0.15);
}
@font-face {
  font-family: "Muli-Regular";
  src: url("fonts/muli/Muli-Regular.ttf"); }
@font-face {
  font-family: "Muli-SemiBold";
  src: url("fonts/muli/Muli-SemiBold.ttf"); }
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

body {
  font-family: "Muli-Regular";
  font-size: 14px;
  margin: 0;
  color: #999; }

input, textarea, select, button {
  font-family: "Muli-Regular"; }

img {
  max-width: 100%; }

ul {
  padding-left: 0;
  margin-bottom: 0; }

a {
  text-decoration: none; }

:focus {
  outline: none; }

.wrapper {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #accffe; }

.inner {
  position: relative;
  width: 435px; }

.image-1 {
  position: absolute;
  bottom: -12px;
  left: -191px;
  z-index: 99; }

.image-2 {
  position: absolute;
  bottom: 0;
  right: -129px; }

form {
  width: 100%;
  position: relative;
  z-index: 9;
  padding: 77px 61px 66px;
  background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  -ms-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  -o-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2); }

h3 {
  text-transform: uppercase;
  font-size: 25px;
  font-family: "Muli-SemiBold";
  color: #333;
  letter-spacing: 3px;
  text-align: center;
  margin-bottom: 33px; }

.form-holder {
  position: relative;
  margin-bottom: 21px; }
  .form-holder span {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    font-size: 15px;
    color: #333; }
    .form-holder span.lnr-lock {
      left: 2px; }

.form-control {
  border: none;
  border-bottom: 1px solid #e6e6e6;
  display: block;
  width: 100%;
  height: 38px;
  background: none;
  padding: 3px 42px 0px;
  color: #666;
  font-family: "Muli-SemiBold";
  font-size: 16px; }
  .form-control::-webkit-input-placeholder {
    font-size: 14px;
    font-family: "Muli-Regular";
    color: #999;
    transform: translateY(1px); }
  .form-control::-moz-placeholder {
    font-size: 14px;
    font-family: "Muli-Regular";
    color: #999;
    transform: translateY(1px); }
  .form-control:-ms-input-placeholder {
    font-size: 14px;
    font-family: "Muli-Regular";
    color: #999;
    transform: translateY(1px); }
  .form-control:-moz-placeholder {
    font-size: 14px;
    font-family: "Muli-Regular";
    color: #999;
    transform: translateY(1px); }
  .form-control:focus {
    border-bottom: 1px solid #accffe; }

    h5{
      font-size: 17px;
      text-align: center;
      font-family: "Muli-SemiBold";
      font-weight: bold;
    }

button {
  border: none;
  width: 100%;
  height: 49px;
  margin-top: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  background: #99ccff;
  color: #fff;
  text-transform: uppercase;
  font-family: "Muli-SemiBold";
  font-size: 15px;
  letter-spacing: 2px;
  transition: all 0.5s;
  position: relative;
  overflow: hidden; }
  button span {
    position: relative;
    z-index: 2; }
  button:before, button:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(52, 152, 253, 0.25);
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    -webkit-transform: translate(-100%, 0);
    transform: translate(-100%, 0);
    -webkit-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
    transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1); }
  button:after {
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s; }
  button:hover:before, button:hover:after {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0); }

@media (max-width: 991px) {
  .inner {
    width: 400px;
    left: 4%; } }
@media (max-width: 767px) {
  .inner {
    width: 100%;
    left: 0; }

  .image-1, .image-2 {
    display: none; }

  form {
    padding: 100px;
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -ms-box-shadow: none;
    -o-box-shadow: none; }

  .wrapper {
    background: none; } }
    .password-input-container {
      position: relative;
    }
    
    /* Eye icon style */
    .toggle-password {
      position: absolute;
      top: 50%;
      right: -10px;
      transform: translateY(-50%);
      cursor: pointer;
    }
/*# sourceMappingURL=style.css.map */
