@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;1,400&display=swap");

body {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
}
.danger{
  color: red;
}
.container {
  max-width: 3000px;
  margin: 0 auto;
  padding: 1rem;
  text-align: center;
  background: #accffe;
}

.heading,.nama {
  font-weight: 700; 
  font-size: 30px;
  text-align: center;
  margin-bottom: 40px;
}
.wrapper {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #accffe; }

.grid-main {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 15px;
  margin: 40px;
}

.grid-child {
  border: 2px solid rgb(251, 255, 33);
  border-radius: 10px;
  padding: 20px;
  cursor: pointer;
  background-color: #051d20;
  color: #fff;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.grid-child:hover {
  background-color: rgb(251, 255, 33);
  color: #ffffff;
}
.inner {
  position: relative;
  width: 20000px; }

.grid-child h3 {
  font-family: inherit;
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 10px;
  text-align: center;
}

.grid-child p {
  font-weight: 500;
  font-size: 15px;
  text-align: center;
}
.label {
  text-align: left;
}
.button.is-danger.is-outlined {
  background-color: red;
  border: 1px solid red;
  color: white;
  padding: 10px 20px;
  margin: 10px;
}
.button .is-primary .is-outlined{
  background-color: rgb(5, 170, 14);
  border: 1px solid rgb(5, 170, 14);
  color: white;
  padding: 10px 20px;
  margin-right: 20px;
}

.button.is-danger.is-outlined .icon {
  color: red;
}
.text-area {
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  padding: 10px;
  width: 100%;
  max-width: 80%;
  margin-top: 20px;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.action-btn {
  width: 200px;
  height: 50px;
  font-size: 20px;
  margin-top: 20px;
  cursor: pointer;
  background-color: #4caf50;
  color: #fff;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  
  /* Tambahkan properti berikut */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.action-btn:hover {
  background-color: #45a049;
}
.image-1 {
  position: absolute;
  bottom: -12px;
  left: -191px;
  z-index: 99; }

.image-2 {
  position: absolute;
  bottom: 0;
  right: -129px; }

.result-text {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  line-height: 2.5rem;
  border-radius: 5px;
  padding: 20px;
  margin: 2% auto 0;
  max-width: 78%;
  border: 1px solid rgb(14, 14, 12);
}
.logo-image {
  width: 160px;
  height: 160px;
  display: block;
  margin: 2% auto 0;
}

/* Tambahkan media query untuk responsif */
@media screen and (max-width: 768px) {
  :root{
    font-size: 12px;
  }
  .action-btn {
    width: 100%;
    max-width: 200px;
  }
  .text-area {
    max-width: 100%;
  }
  .logo-image {
    margin: 10px auto 0px;
    width: 100px;
    height: 100px;
  }
  .grid-main {
    display: grid;
    grid-template-columns: auto;
    gap: 10px;
    margin: 5px;
  }
  .heading,.grid-child h3{
    font-size: 12px;
  } 
  .text-area,.result-text,.grid-child p{
    font-size: 10px;
  }
  .image-1, .image-2 {
    display: none; }
}