@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;1,400&display=swap);
body {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
}
.danger{
  color: red;
}
.container {
  max-width: 3000px;
  margin: 0 auto;
  padding: 1rem;
  text-align: center;
  background: #accffe;
}

.heading,.nama {
  font-weight: 700; 
  font-size: 30px;
  text-align: center;
  margin-bottom: 40px;
}
.wrapper {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #accffe; }

.grid-main {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 15px;
  gap: 15px;
  margin: 40px;
}

.grid-child {
  border: 2px solid rgb(251, 255, 33);
  border-radius: 10px;
  padding: 20px;
  cursor: pointer;
  background-color: #051d20;
  color: #fff;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.grid-child:hover {
  background-color: rgb(251, 255, 33);
  color: #ffffff;
}
.inner {
  position: relative;
  width: 20000px; }

.grid-child h3 {
  font-family: inherit;
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 10px;
  text-align: center;
}

.grid-child p {
  font-weight: 500;
  font-size: 15px;
  text-align: center;
}
.label {
  text-align: left;
}
.button.is-danger.is-outlined {
  background-color: red;
  border: 1px solid red;
  color: white;
  padding: 10px 20px;
  margin: 10px;
}
.button .is-primary .is-outlined{
  background-color: rgb(5, 170, 14);
  border: 1px solid rgb(5, 170, 14);
  color: white;
  padding: 10px 20px;
  margin-right: 20px;
}

.button.is-danger.is-outlined .icon {
  color: red;
}
.text-area {
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  padding: 10px;
  width: 100%;
  max-width: 80%;
  margin-top: 20px;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.action-btn {
  width: 200px;
  height: 50px;
  font-size: 20px;
  margin-top: 20px;
  cursor: pointer;
  background-color: #4caf50;
  color: #fff;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  
  /* Tambahkan properti berikut */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.action-btn:hover {
  background-color: #45a049;
}
.image-1 {
  position: absolute;
  bottom: -12px;
  left: -191px;
  z-index: 99; }

.image-2 {
  position: absolute;
  bottom: 0;
  right: -129px; }

.result-text {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  line-height: 2.5rem;
  border-radius: 5px;
  padding: 20px;
  margin: 2% auto 0;
  max-width: 78%;
  border: 1px solid rgb(14, 14, 12);
}
.logo-image {
  width: 160px;
  height: 160px;
  display: block;
  margin: 2% auto 0;
}

/* Tambahkan media query untuk responsif */
@media screen and (max-width: 768px) {
  :root{
    font-size: 12px;
  }
  .action-btn {
    width: 100%;
    max-width: 200px;
  }
  .text-area {
    max-width: 100%;
  }
  .logo-image {
    margin: 10px auto 0px;
    width: 100px;
    height: 100px;
  }
  .grid-main {
    display: grid;
    grid-template-columns: auto;
    grid-gap: 10px;
    gap: 10px;
    margin: 5px;
  }
  .heading,.grid-child h3{
    font-size: 12px;
  } 
  .text-area,.result-text,.grid-child p{
    font-size: 10px;
  }
  .image-1, .image-2 {
    display: none; }
}
.glassmorphic {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  transition: background 0.3s ease-in-out;
}

.glassmorphic:hover {
  background: rgba(255, 255, 255, 0.15);
}
@font-face {
  font-family: "Muli-Regular";
  src: url(/static/media/Muli-Regular.426119c6.ttf); }
@font-face {
  font-family: "Muli-SemiBold";
  src: url(/static/media/Muli-SemiBold.dbbd674e.ttf); }
* {
  box-sizing: border-box; }

body {
  font-family: "Muli-Regular";
  font-size: 14px;
  margin: 0;
  color: #999; }

input, textarea, select, button {
  font-family: "Muli-Regular"; }

img {
  max-width: 100%; }

ul {
  padding-left: 0;
  margin-bottom: 0; }

a {
  text-decoration: none; }

:focus {
  outline: none; }

.wrapper {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #accffe; }

.inner {
  position: relative;
  width: 435px; }

.image-1 {
  position: absolute;
  bottom: -12px;
  left: -191px;
  z-index: 99; }

.image-2 {
  position: absolute;
  bottom: 0;
  right: -129px; }

form {
  width: 100%;
  position: relative;
  z-index: 9;
  padding: 77px 61px 66px;
  background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  -ms-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  -o-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2); }

h3 {
  text-transform: uppercase;
  font-size: 25px;
  font-family: "Muli-SemiBold";
  color: #333;
  letter-spacing: 3px;
  text-align: center;
  margin-bottom: 33px; }

.form-holder {
  position: relative;
  margin-bottom: 21px; }
  .form-holder span {
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    font-size: 15px;
    color: #333; }
    .form-holder span.lnr-lock {
      left: 2px; }

.form-control {
  border: none;
  border-bottom: 1px solid #e6e6e6;
  display: block;
  width: 100%;
  height: 38px;
  background: none;
  padding: 3px 42px 0px;
  color: #666;
  font-family: "Muli-SemiBold";
  font-size: 16px; }
  .form-control::-webkit-input-placeholder {
    font-size: 14px;
    font-family: "Muli-Regular";
    color: #999;
    -webkit-transform: translateY(1px);
            transform: translateY(1px); }
  .form-control::-moz-placeholder {
    font-size: 14px;
    font-family: "Muli-Regular";
    color: #999;
    transform: translateY(1px); }
  .form-control:-ms-input-placeholder {
    font-size: 14px;
    font-family: "Muli-Regular";
    color: #999;
    transform: translateY(1px); }
  .form-control:-moz-placeholder {
    font-size: 14px;
    font-family: "Muli-Regular";
    color: #999;
    transform: translateY(1px); }
  .form-control:focus {
    border-bottom: 1px solid #accffe; }

    h5{
      font-size: 17px;
      text-align: center;
      font-family: "Muli-SemiBold";
      font-weight: bold;
    }

button {
  border: none;
  width: 100%;
  height: 49px;
  margin-top: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  background: #99ccff;
  color: #fff;
  text-transform: uppercase;
  font-family: "Muli-SemiBold";
  font-size: 15px;
  letter-spacing: 2px;
  transition: all 0.5s;
  position: relative;
  overflow: hidden; }
  button span {
    position: relative;
    z-index: 2; }
  button:before, button:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(52, 152, 253, 0.25);
    transition: all 0.3s;
    -webkit-transform: translate(-100%, 0);
    transform: translate(-100%, 0);
    transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1); }
  button:after {
    transition-delay: 0.2s; }
  button:hover:before, button:hover:after {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0); }

@media (max-width: 991px) {
  .inner {
    width: 400px;
    left: 4%; } }
@media (max-width: 767px) {
  .inner {
    width: 100%;
    left: 0; }

  .image-1, .image-2 {
    display: none; }

  form {
    padding: 100px;
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -ms-box-shadow: none;
    -o-box-shadow: none; }

  .wrapper {
    background: none; } }
    .password-input-container {
      position: relative;
    }
    
    /* Eye icon style */
    .toggle-password {
      position: absolute;
      top: 50%;
      right: -10px;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
      cursor: pointer;
    }
/*# sourceMappingURL=style.css.map */

@font-face {
	font-family: 'Linearicons-Free';
	src:url(/static/media/Linearicons-Free.eb992e96.eot);
	src:url(/static/media/Linearicons-Free.eb992e96.eot?#iefixw118d) format('embedded-opentype'),
		url(/static/media/Linearicons-Free.857c65d6.woff2) format('woff2'),
		url(/static/media/Linearicons-Free.a78a26fb.woff) format('woff'),
		url(/static/media/Linearicons-Free.5cbe0a85.ttf) format('truetype'),
		url(/static/media/Linearicons-Free.638c6c05.svg#Linearicons-Free) format('svg');
	font-weight: normal;
	font-style: normal;
}

.lnr {
	font-family: 'Linearicons-Free';
	speak: none;
	font-style: normal;
	font-weight: normal;
	-webkit-font-feature-settings: normal;
	        font-feature-settings: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.lnr-home:before {
	content: "\e800";
}
.lnr-apartment:before {
	content: "\e801";
}
.lnr-pencil:before {
	content: "\e802";
}
.lnr-magic-wand:before {
	content: "\e803";
}
.lnr-drop:before {
	content: "\e804";
}
.lnr-lighter:before {
	content: "\e805";
}
.lnr-poop:before {
	content: "\e806";
}
.lnr-sun:before {
	content: "\e807";
}
.lnr-moon:before {
	content: "\e808";
}
.lnr-cloud:before {
	content: "\e809";
}
.lnr-cloud-upload:before {
	content: "\e80a";
}
.lnr-cloud-download:before {
	content: "\e80b";
}
.lnr-cloud-sync:before {
	content: "\e80c";
}
.lnr-cloud-check:before {
	content: "\e80d";
}
.lnr-database:before {
	content: "\e80e";
}
.lnr-lock:before {
	content: "\e80f";
}
.lnr-cog:before {
	content: "\e810";
}
.lnr-trash:before {
	content: "\e811";
}
.lnr-dice:before {
	content: "\e812";
}
.lnr-heart:before {
	content: "\e813";
}
.lnr-star:before {
	content: "\e814";
}
.lnr-star-half:before {
	content: "\e815";
}
.lnr-star-empty:before {
	content: "\e816";
}
.lnr-flag:before {
	content: "\e817";
}
.lnr-envelope:before {
	content: "\e818";
}
.lnr-paperclip:before {
	content: "\e819";
}
.lnr-inbox:before {
	content: "\e81a";
}
.lnr-eye:before {
	content: "\e81b";
}
.lnr-printer:before {
	content: "\e81c";
}
.lnr-file-empty:before {
	content: "\e81d";
}
.lnr-file-add:before {
	content: "\e81e";
}
.lnr-enter:before {
	content: "\e81f";
}
.lnr-exit:before {
	content: "\e820";
}
.lnr-graduation-hat:before {
	content: "\e821";
}
.lnr-license:before {
	content: "\e822";
}
.lnr-music-note:before {
	content: "\e823";
}
.lnr-film-play:before {
	content: "\e824";
}
.lnr-camera-video:before {
	content: "\e825";
}
.lnr-camera:before {
	content: "\e826";
}
.lnr-picture:before {
	content: "\e827";
}
.lnr-book:before {
	content: "\e828";
}
.lnr-bookmark:before {
	content: "\e829";
}
.lnr-user:before {
	content: "\e82a";
}
.lnr-users:before {
	content: "\e82b";
}
.lnr-shirt:before {
	content: "\e82c";
}
.lnr-store:before {
	content: "\e82d";
}
.lnr-cart:before {
	content: "\e82e";
}
.lnr-tag:before {
	content: "\e82f";
}
.lnr-phone-handset:before {
	content: "\e830";
}
.lnr-phone:before {
	content: "\e831";
}
.lnr-pushpin:before {
	content: "\e832";
}
.lnr-map-marker:before {
	content: "\e833";
}
.lnr-map:before {
	content: "\e834";
}
.lnr-location:before {
	content: "\e835";
}
.lnr-calendar-full:before {
	content: "\e836";
}
.lnr-keyboard:before {
	content: "\e837";
}
.lnr-spell-check:before {
	content: "\e838";
}
.lnr-screen:before {
	content: "\e839";
}
.lnr-smartphone:before {
	content: "\e83a";
}
.lnr-tablet:before {
	content: "\e83b";
}
.lnr-laptop:before {
	content: "\e83c";
}
.lnr-laptop-phone:before {
	content: "\e83d";
}
.lnr-power-switch:before {
	content: "\e83e";
}
.lnr-bubble:before {
	content: "\e83f";
}
.lnr-heart-pulse:before {
	content: "\e840";
}
.lnr-construction:before {
	content: "\e841";
}
.lnr-pie-chart:before {
	content: "\e842";
}
.lnr-chart-bars:before {
	content: "\e843";
}
.lnr-gift:before {
	content: "\e844";
}
.lnr-diamond:before {
	content: "\e845";
}
.lnr-linearicons:before {
	content: "\e846";
}
.lnr-dinner:before {
	content: "\e847";
}
.lnr-coffee-cup:before {
	content: "\e848";
}
.lnr-leaf:before {
	content: "\e849";
}
.lnr-paw:before {
	content: "\e84a";
}
.lnr-rocket:before {
	content: "\e84b";
}
.lnr-briefcase:before {
	content: "\e84c";
}
.lnr-bus:before {
	content: "\e84d";
}
.lnr-car:before {
	content: "\e84e";
}
.lnr-train:before {
	content: "\e84f";
}
.lnr-bicycle:before {
	content: "\e850";
}
.lnr-wheelchair:before {
	content: "\e851";
}
.lnr-select:before {
	content: "\e852";
}
.lnr-earth:before {
	content: "\e853";
}
.lnr-smile:before {
	content: "\e854";
}
.lnr-sad:before {
	content: "\e855";
}
.lnr-neutral:before {
	content: "\e856";
}
.lnr-mustache:before {
	content: "\e857";
}
.lnr-alarm:before {
	content: "\e858";
}
.lnr-bullhorn:before {
	content: "\e859";
}
.lnr-volume-high:before {
	content: "\e85a";
}
.lnr-volume-medium:before {
	content: "\e85b";
}
.lnr-volume-low:before {
	content: "\e85c";
}
.lnr-volume:before {
	content: "\e85d";
}
.lnr-mic:before {
	content: "\e85e";
}
.lnr-hourglass:before {
	content: "\e85f";
}
.lnr-undo:before {
	content: "\e860";
}
.lnr-redo:before {
	content: "\e861";
}
.lnr-sync:before {
	content: "\e862";
}
.lnr-history:before {
	content: "\e863";
}
.lnr-clock:before {
	content: "\e864";
}
.lnr-download:before {
	content: "\e865";
}
.lnr-upload:before {
	content: "\e866";
}
.lnr-enter-down:before {
	content: "\e867";
}
.lnr-exit-up:before {
	content: "\e868";
}
.lnr-bug:before {
	content: "\e869";
}
.lnr-code:before {
	content: "\e86a";
}
.lnr-link:before {
	content: "\e86b";
}
.lnr-unlink:before {
	content: "\e86c";
}
.lnr-thumbs-up:before {
	content: "\e86d";
}
.lnr-thumbs-down:before {
	content: "\e86e";
}
.lnr-magnifier:before {
	content: "\e86f";
}
.lnr-cross:before {
	content: "\e870";
}
.lnr-menu:before {
	content: "\e871";
}
.lnr-list:before {
	content: "\e872";
}
.lnr-chevron-up:before {
	content: "\e873";
}
.lnr-chevron-down:before {
	content: "\e874";
}
.lnr-chevron-left:before {
	content: "\e875";
}
.lnr-chevron-right:before {
	content: "\e876";
}
.lnr-arrow-up:before {
	content: "\e877";
}
.lnr-arrow-down:before {
	content: "\e878";
}
.lnr-arrow-left:before {
	content: "\e879";
}
.lnr-arrow-right:before {
	content: "\e87a";
}
.lnr-move:before {
	content: "\e87b";
}
.lnr-warning:before {
	content: "\e87c";
}
.lnr-question-circle:before {
	content: "\e87d";
}
.lnr-menu-circle:before {
	content: "\e87e";
}
.lnr-checkmark-circle:before {
	content: "\e87f";
}
.lnr-cross-circle:before {
	content: "\e880";
}
.lnr-plus-circle:before {
	content: "\e881";
}
.lnr-circle-minus:before {
	content: "\e882";
}
.lnr-arrow-up-circle:before {
	content: "\e883";
}
.lnr-arrow-down-circle:before {
	content: "\e884";
}
.lnr-arrow-left-circle:before {
	content: "\e885";
}
.lnr-arrow-right-circle:before {
	content: "\e886";
}
.lnr-chevron-up-circle:before {
	content: "\e887";
}
.lnr-chevron-down-circle:before {
	content: "\e888";
}
.lnr-chevron-left-circle:before {
	content: "\e889";
}
.lnr-chevron-right-circle:before {
	content: "\e88a";
}
.lnr-crop:before {
	content: "\e88b";
}
.lnr-frame-expand:before {
	content: "\e88c";
}
.lnr-frame-contract:before {
	content: "\e88d";
}
.lnr-layers:before {
	content: "\e88e";
}
.lnr-funnel:before {
	content: "\e88f";
}
.lnr-text-format:before {
	content: "\e890";
}
.lnr-text-format-remove:before {
	content: "\e891";
}
.lnr-text-size:before {
	content: "\e892";
}
.lnr-bold:before {
	content: "\e893";
}
.lnr-italic:before {
	content: "\e894";
}
.lnr-underline:before {
	content: "\e895";
}
.lnr-strikethrough:before {
	content: "\e896";
}
.lnr-highlight:before {
	content: "\e897";
}
.lnr-text-align-left:before {
	content: "\e898";
}
.lnr-text-align-center:before {
	content: "\e899";
}
.lnr-text-align-right:before {
	content: "\e89a";
}
.lnr-text-align-justify:before {
	content: "\e89b";
}
.lnr-line-spacing:before {
	content: "\e89c";
}
.lnr-indent-increase:before {
	content: "\e89d";
}
.lnr-indent-decrease:before {
	content: "\e89e";
}
.lnr-pilcrow:before {
	content: "\e89f";
}
.lnr-direction-ltr:before {
	content: "\e8a0";
}
.lnr-direction-rtl:before {
	content: "\e8a1";
}
.lnr-page-break:before {
	content: "\e8a2";
}
.lnr-sort-alpha-asc:before {
	content: "\e8a3";
}
.lnr-sort-amount-asc:before {
	content: "\e8a4";
}
.lnr-hand:before {
	content: "\e8a5";
}
.lnr-pointer-up:before {
	content: "\e8a6";
}
.lnr-pointer-right:before {
	content: "\e8a7";
}
.lnr-pointer-down:before {
	content: "\e8a8";
}
.lnr-pointer-left:before {
	content: "\e8a9";
}

